<script setup lang="ts">
import { useHeaderMainStore } from '~/store'
import IconTelegramSvg from '@/assets/icons/social/telegram.svg?component'
import IconInstagramSvg from '@/assets/icons/social/instagram.svg?component'
import IconLinkedinSvg from '@/assets/icons/social/linkedin.svg?component'
import IconYoutubeSvg from '@/assets/icons/social/youtube.svg?component'
import IconFacebookSvg from '@/assets/icons/social/facebook.svg?component'
import IconTwitterSvg from '@/assets/icons/social/facebook.svg?component'

const openedTab = ref('')
const store = useHeaderMainStore()
const route = useRoute()
const i18n = useI18n()
const updateKey = computed(() =>
    route.query.region
        ? route.query.region + '-' + i18n.locale.value
        : `global-${i18n.locale.value}`
)

const associationName = computed(() => {
    const associationFromStore = store.menuList?.find(
        element => element?.slug === 'association'
    )
    if (
        associationFromStore &&
        associationFromStore?.translations?.length
    ) {
        return associationFromStore?.translations[0].name
    }
    return ''
})

const currentYear = computed(() => new Date().getFullYear())
</script>
<template>
    <footer :key="updateKey" class="footer">
        <div
            v-if="store?.industries && store?.specializations"
            class="container"
        >
            <!-- Footer navigation -->
            <div class="footer-nav">
                <!-- footer-list -->
                <!-- <div class="footer-list is-open">
        класс "is-open" открывает скрытые ссылки меню -->
                <div
                    v-if="store?.industries"
                    class="footer-list"
                    style="flex-wrap: nowrap; overflow: hidden"
                    :style="`padding-bottom: ${
                        openedTab === 'industries' ? '10px' : '0'
                    }`"
                >
                    <p
                        class="footer-list__title footer-list__accordion"
                        :class="{
                            'footer-list__accordion--opened':
                                openedTab === 'industries'
                        }"
                        @click.prevent="
                            openedTab =
                                openedTab === 'industries'
                                    ? ''
                                    : 'industries'
                        "
                    >
                        {{ $t('industrySectors') }}
                    </p>
                    <AppNuxtLinkLocale
                        v-for="item in store?.industries"
                        :key="item.id"
                        :to="{
                            path: `/industries/${item?.slug}`
                        }"
                        class="footer-list__link"
                        :class="{
                            opened:
                                $route.name?.split('__')[0] ===
                                    'industries' ||
                                openedTab === 'industries'
                        }"
                    >
                        {{ item.name || 'no name in translations' }}
                    </AppNuxtLinkLocale>
                </div>

                <!-- footer-list -->

                <div
                    v-if="store?.specializations"
                    class="footer-list footer-list--long"
                    style="flex-flow: column"
                >
                    <p
                        class="footer-list__title footer-list__accordion"
                        :class="{
                            'footer-list__accordion--opened':
                                openedTab === 'practice'
                        }"
                        @click.prevent="
                            openedTab =
                                openedTab === 'practice'
                                    ? ''
                                    : 'practice'
                        "
                    >
                        <!-- <AppNuxtLinkLocale
                            tag="h3"
                            :to="{ path: '/specializations' }"
                        > -->
                        {{ $t('practiceAreas') }}
                        <!-- </AppNuxtLinkLocale> -->
                    </p>
                    <div class="footer-specializations" style="">
                        <AppNuxtLinkLocale
                            v-for="item in store?.specializations"
                            :key="item.id"
                            :to="{
                                path: `/specializations/${
                                    item?.slug || 'no-dep-slug'
                                }`
                            }"
                            :class="{
                                opened: openedTab === 'practice'
                            }"
                            class="footer-list__link"
                        >
                            {{
                                item.name || 'no name in translations'
                            }}
                        </AppNuxtLinkLocale>
                    </div>
                </div>

                <!-- footer-items -->
                <div class="footer-items">
                    <!-- footer-items__links -->
                    <div
                        class="footer-items__links"
                        style="flex-flow: column"
                    >
                        <div class="footer-list">
                            <AppNuxtLinkLocale
                                :to="{ path: '/insights' }"
                                href="#"
                                class="footer-list__title"
                                >{{ $t('insights') }}
                            </AppNuxtLinkLocale>
                            <AppNuxtLinkLocale
                                :to="{ path: '/association' }"
                                href="#"
                                class="footer-list__title"
                            >
                                {{ associationName }}
                            </AppNuxtLinkLocale>
                        </div>

                        <div class="footer-list">
                            <AppNuxtLinkLocale
                                class="footer-list__title"
                                :to="{ path: '/career' }"
                            >
                                {{ $t('career.career') }}
                            </AppNuxtLinkLocale>
                            <AppNuxtLinkLocale
                                class="footer-list__title"
                                :to="{ path: '/contacts' }"
                            >
                                {{ $t('contactus') }}
                            </AppNuxtLinkLocale>
                        </div>
                    </div>

                    <!-- footer-search -->
                </div>
            </div>

            <!-- Footer links -->
            <div class="footer-links">
                <!-- footer-links__top -->
                <div class="footer-links__terms footer-links__mobile">
                    <ul>
                        <li>
                            <AppNuxtLinkLocale
                                :to="{ path: '/disclaimer' }"
                            >
                                {{ $t('footerSubMenu.disclaimer') }}
                            </AppNuxtLinkLocale>
                            <!-- <a href="#">Disclaimer</a> -->
                        </li>
                        <li>
                            <AppNuxtLinkLocale
                                :to="{ path: '/privacy-policy' }"
                            >
                                {{
                                    $t('footerSubMenu.privacyPolicy')
                                }}
                            </AppNuxtLinkLocale>
                        </li>
                        <li>
                            <AppNuxtLinkLocale
                                :to="{ path: '/cookies' }"
                            >
                                {{ $t('footerSubMenu.cookies') }}
                            </AppNuxtLinkLocale>
                        </li>
                        <li>
                            <AppNuxtLinkLocale
                                :to="{ path: '/sitemap' }"
                                >{{ $t('footerSubMenu.siteMap') }}
                            </AppNuxtLinkLocale>
                        </li>
                    </ul>
                </div>
                <div class="footer-links__top">
                    <FormSubscribeByEmail />

                    <LanguageSwitcher position="footer" />
                </div>

                <!-- footer-links__bottom -->
                <div class="footer-links__bottom">
                    <div class="footer-links__terms">
                        <ul>
                            <li>
                                <span>
                                    © {{ currentYear }} GRATA
                                    International</span
                                >
                            </li>
                            <li>
                                <AppNuxtLinkLocale
                                    :to="{ path: '/disclaimer' }"
                                >
                                    {{
                                        $t('footerSubMenu.disclaimer')
                                    }}
                                </AppNuxtLinkLocale>
                                <!-- <a href="#">Disclaimer</a> -->
                            </li>
                            <li>
                                <AppNuxtLinkLocale
                                    :to="{ path: '/privacy-policy' }"
                                >
                                    {{
                                        $t(
                                            'footerSubMenu.privacyPolicy'
                                        )
                                    }}
                                </AppNuxtLinkLocale>
                            </li>
                            <li>
                                <AppNuxtLinkLocale
                                    :to="{ path: '/cookies' }"
                                >
                                    {{ $t('footerSubMenu.cookies') }}
                                </AppNuxtLinkLocale>
                            </li>
                            <li>
                                <AppNuxtLinkLocale
                                    :to="{ path: '/sitemap' }"
                                    >{{ $t('footerSubMenu.siteMap') }}
                                </AppNuxtLinkLocale>
                            </li>
                        </ul>
                    </div>
                    <div class="footer-links__socials">
                        <a
                            href="https://t.me/gratanet"
                            target="_blank"
                            class="footer-links__item"
                        >
                            <IconTelegramSvg
                                alt="telegram"
                                width="18"
                                height="18"
                            />
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UCPxiS1gz0G6yjNzNLE9xz2A"
                            target="_blank"
                            class="footer-links__item"
                        >
                            <IconYoutubeSvg
                                alt="youtube"
                                width="18"
                                height="18"
                            />
                        </a>
                        <a
                            href="https://www.facebook.com/gratanet/"
                            target="_blank"
                            class="footer-links__item"
                        >
                            <IconFacebookSvg
                                alt="facebook"
                                width="18"
                                height="18"
                            />
                        </a>
                        <a
                            href="https://twitter.com/gratanet"
                            target="_blank"
                            class="footer-links__item"
                        >
                            <IconTwitterSvg
                                alt="twitter"
                                width="18"
                                height="18"
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/grata_international/"
                            target="_blank"
                            class="footer-links__item"
                        >
                            <IconInstagramSvg
                                alt="instagram"
                                width="18"
                                style="filter: invert(100%)"
                                height="18"
                            />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/grata"
                            target="_blank"
                            class="footer-links__item"
                        >
                            <IconLinkedinSvg
                                alt="linkedin"
                                width="18"
                                style="filter: invert(100%)"
                                height="18"
                            />
                        </a>
                    </div>
                </div>
                <div
                    class="footer-links__mobile"
                    style="margin-top: 10px; justify-content: center"
                >
                    <span style="font-size: 9px; color: #fff">
                        © {{ currentYear }} GRATA International</span
                    >
                </div>
            </div>
        </div>
    </footer>
</template>
