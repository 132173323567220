<script lang="ts" setup>
import { onMounted } from 'vue'
import { useHeaderMainStore } from '~/store'

import HeaderSubMenu from '~/components/Header/HeaderSubMenu.vue'

interface Props {
    headerHeight: number
}

interface Emit {
    (e: 'clickMenuItem', slug: string): void
}

const emit = defineEmits<Emit>()
defineProps<Props>()

const store = useHeaderMainStore()
const isOpenedSubMenu = ref(false)
const subMenuRef = ref()
useDetectOutsideClick(subMenuRef, () => {
    isOpenedSubMenu.value = false
    // alert()
})
const route = useRoute()

watch(
    () => route.fullPath,
    () => {
        isOpenedSubMenu.value = false
    },
    { deep: true }
)

function onClickItem(slug: string) {
    // if (isOpenedSubMenu.value) {
    //     document.querySelector('body').style.overflow = 'initial'
    // } else {
    //     document.querySelector('body').style.overflow = 'hidden'
    // }
    document.querySelector('.popup')?.classList.add('hide-popup')
    emit('clickMenuItem', slug)
    if (slug === 'what-we-do') {
        isOpenedSubMenu.value = !isOpenedSubMenu.value
    }
}

// $route?.name?.split('__')[0] === 'career'
function currentLink(item: any) {
    let a = false

    switch (item) {
        case 'career':
            a = [
                'career',
                'career-young',
                'career-administrative'
            ].includes(route.name?.split('__')[0])
            break
        case 'regions':
            a = route.name?.split('__')[0] === 'countryslug-regions'
            break
        case 'about':
            a = [
                'global',
                'about',
                'collaboration',
                'pro-bono',
                'recognition',
                'contacts'
            ].includes(route.name?.split('__')[0])
            break
        case 'association':
            a = route.name?.split('__')[0] === 'association'
            break
        case 'employees':
            a = route.name?.split('__')[0] === 'employees'
            break
        default:
            break
    }
    return a
}

const headerPositoin = ref(105)
onMounted(() => {
    window.addEventListener('scroll', () => {
        isOpenedSubMenu.value = false
        headerPositoin.value = window.scrollY + 105
    })
})

function removeHidePopupClass() {
    document.querySelector('.popup')?.classList.remove('hide-popup')
}

const { isLoading } = useLoadingIndicator()
const positionSubMenu = computed(() =>
    store.isHeaderFixed ? '70px' : '105px'
)
</script>

<template>
    <div ref="subMenuRef" class="header__links">
        <div
            v-for="item in store.menuList"
            :key="item.slug"
            class="header-link-container-list"
            :class="{
                'has-sub-menu': item.slug === 'what-we-do'
            }"
            @click="onClickItem(item.slug)"
            @mouseenter="removeHidePopupClass"
        >
            <template v-if="item.slug === 'what-we-do'">
                <a
                    class="header-link header-link--list"
                    :class="{
                        'header-link--list-active':
                            isOpenedSubMenu ||
                            $route?.name?.split('__')[0] === 'about'
                    }"
                    @mouseenter="isOpenedSubMenu = true"
                    @mouseleave="isOpenedSubMenu = false"
                >
                    {{
                        item.translations[0]?.name ||
                        `menu-${item.id}-lang-${item.translations[0]?.language?.charCode}`
                    }}</a
                >
                <HeaderSubMenu
                    :is-opened="isOpenedSubMenu && !isLoading"
                    :style="{ top: positionSubMenu }"
                    @mouseenter="isOpenedSubMenu = true"
                    @mouseleave="isOpenedSubMenu = false"
                />
            </template>
            <AppNuxtLinkLocale
                v-else-if="item.slug !== 'association'"
                class="header-link"
                :class="{
                    'header-link--item-active': currentLink(item.slug)
                }"
                :to="{
                    path:
                        item.slug === 'regions'
                            ? `/${store?.selectedRegion?.slug}/regions`
                            : `/${item.slug}`
                }"
            >
                {{
                    item.translations[0]?.name ||
                    `menu-${item.id}-lang-${item.translations[0]?.language?.charCode}`
                }}
            </AppNuxtLinkLocale>
            <template v-if="item.slug == 'association'">
                <a
                    href="https://gi.gratanet.com"
                    class="header-link"
                    >{{
                        item.translations[0]?.name ||
                        `menu-${item.id}-lang-${item.translations[0]?.language?.charCode}`
                    }}</a
                >
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>
